const footerSliderSettings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: false,
    variableWidth: false,
    mobileFirst: true,
    prevArrow: jQuery( ".footer-logo-slider-prev" ),
    nextArrow: jQuery( ".footer-logo-slider-next" ),
    responsive: [{
        breakpoint: 769,
        settings: "unslick"
    }]
};

const footerSlider = jQuery( '.footer-logo-slider' ).slick( footerSliderSettings );

jQuery( window ).on( 'resize', function() {
    if ( jQuery( window ).width() < 769 && !footerSlider.hasClass( 'slick-initialized' ) ) {
        jQuery( '.footer-logo-slider' ).slick( footerSliderSettings );
    }
} );

jQuery( document ).ready( function() {
    if ( jQuery( window ).width() < 769 && !footerSlider.hasClass( 'slick-initialized' ) ) {
        jQuery( '.footer-logo-slider' ).slick( footerSliderSettings );
    }
} );