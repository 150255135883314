jQuery( '.team-slider' ).slick( {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  centerMode: true,
  centerPadding: '200px',
  prevArrow: jQuery( ".team-slider-prev" ),
  nextArrow: jQuery( ".team-slider-next" ),
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        centerPadding: '60px'
      }
    },
    {
      breakpoint: 1400,
      settings: {
        centerPadding: '100px',
      }
    },
    {
      breakpoint: 1200,
      settings: {
        centerPadding: '50px',
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        centerPadding: '20px'
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        centerPadding: '20px'
      }
    }
    // {
    //   breakpoint: 1000,
    //   settins: {
    //     centerPadding: '0',
    //   }
    // }
  ]
  // responsive: [
  //   {
  //     breakpoint: 768,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 1,
  //       centerMode: false
  //     }
  //   },
  //   {
  //     breakpoint: 639,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1
  //     }
  //   }
  // ]
} );


jQuery( '.team-slider, .team-card-grid' ).on( 'click', '.read-bio', function() {
  const card = this.closest( '.card' );
  const overlay = card.querySelector( '.overlay' );
  jQuery( overlay ).fadeIn( "slow" );
} );

jQuery( '.team-slider, .team-card-grid' ).on( 'click', '.close-bio', function() {
  const card = this.closest( '.card' );
  const overlay = card.querySelector( '.overlay' );
  jQuery( overlay ).fadeOut( "slow" );
} );
