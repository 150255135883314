import AOS from 'aos';

AOS.init( {
    once: true,
    duration: 500,
    anchorPlacement: 'top-bottom',
} );

import 'slick-carousel';
import 'slick-lightbox';

import './template-parts/global.js';
import './template-parts/header.js';
import './template-parts/faqs.js';
import './template-parts/single-post.js';
import './template-parts/homepage-banner.js';
import './template-parts/latest-blogs-slider.js';
import './template-parts/gallery-slider.js';
import './template-parts/gallery-slider-with-nav.js';
import './template-parts/our-clients-mini-cards.js';
import './template-parts/timeline-slider.js';
import './template-parts/our-services-include-slider.js';
import './template-parts/team-slider';
import './template-parts/custom-select';
import './template-parts/project-types-slider';
import './template-parts/footer-logo-slider';
import './template-parts/related-services';
import './template-parts/related-projects';
import './template-parts/related-insights';