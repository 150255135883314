jQuery('.our-clients-mini-cards .client-logo').on('click touch', function() {
    var id = jQuery(this).attr('data-client-card');
    
    jQuery('.client-content').hide();
    jQuery('.client-content[data-client-content="'+id+'"]').css('display', 'flex');

    jQuery('.our-clients-mini-cards .client-logo').removeClass('active');
    jQuery(this).addClass('active');
});


// jQuery('.our-clients-mini-cards-slider').slick({
//     dots: false,
//     infinite: true,
//     speed: 300,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     arrows: true,
//     centerMode: true,
//     // centerPadding: '-200px',  
//     prevArrow: jQuery(".our-clients-mini-cards-prev"),
//     nextArrow: jQuery(".our-clients-mini-cards-next"),
//   });

jQuery('.our-clients-mini-cards-slider').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,
    // centerPadding: '-200px',  
    prevArrow: jQuery(".our-clients-mini-cards-prev"),
    nextArrow: jQuery(".our-clients-mini-cards-next"),
  });

// jQuery(document).ready(function() {
//     var slides = jQuery('.our-clients-mini-cards-slider .slick-slide');
//     slides.each(function(index) {
//     if (jQuery(this).attr('aria-hidden') == 'false') {
//         jQuery(this).prev().css('opacity', '1 !important');
//     } else {
//         jQuery(this).prev().css('opacity', '0.5 !important');
//     }
//     });
// });

// jQuery('.our-clients-mini-cards-slider ').on('afterChange', function(event, slick, currentSlide, nextSlide){
//     var slides = jQuery('.our-clients-mini-cards-slider .slick-slide');
//     slides.each(function(index) {
//     if (jQuery(this).attr('aria-hidden') == 'false') {
//         jQuery(this).prev().css('opacity', '1 !important');
//     } else {
//         jQuery(this).prev().css('opacity', '0.5 !important');
//     }
//     });
// });