jQuery('.gallery-slider.no-nav').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    centerPadding: '0',
    rows: 0,
    prevArrow:'<svg class="slick-arrow slick-prev" width="15" height="24" viewBox="0 0 15 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g id="Path.-Copy.-4" transform="matrix(1,0,0,1,-20,-328)"><path d="M34.742,330.061L24.802,340L34.742,349.939L32.62,352.061L20.56,340L32.62,327.939L34.742,330.061Z"/></g></svg>',
    nextArrow:'<svg class="slick-arrow slick-next" width="15" height="24" viewBox="0 0 15 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g id="Path.-Copy.-3" transform="matrix(-1,7.65714e-16,-7.65714e-16,-1,373.362,352)"><path d="M358.56,340L370.62,327.939L372.742,330.061L362.802,340L372.742,349.939L370.62,352.061L358.56,340Z"/></g></svg>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  
  jQuery('.gallery-slider.no-nav').slickLightbox({
    src: 'href',
    itemSelector: '.image-slide a',
    prevArrow:'<svg class="slick-arrow slick-prev" width="15" height="24" viewBox="0 0 15 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g id="Path.-Copy.-4" transform="matrix(1,0,0,1,-20,-328)"><path d="M34.742,330.061L24.802,340L34.742,349.939L32.62,352.061L20.56,340L32.62,327.939L34.742,330.061Z"/></g></svg>',
    nextArrow:'<svg class="slick-arrow slick-next" width="15" height="24" viewBox="0 0 15 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g id="Path.-Copy.-3" transform="matrix(-1,7.65714e-16,-7.65714e-16,-1,373.362,352)"><path d="M358.56,340L370.62,327.939L372.742,330.061L362.802,340L372.742,349.939L370.62,352.061L358.56,340Z"/></g></svg>',
  });