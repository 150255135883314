// jQuery('.burgerBun').click(function() {
jQuery( '.burgerBun' ).on( 'click tap', function() {
  jQuery( '.burgerBun' ).toggleClass( "open" );
  jQuery( '.menu-mobile-menu-container' ).toggleClass( "open" );
} );
// jQuery('.burgerBun').on('touchstart click', function() {

// });
jQuery( '.menu-mobile-menu-container a' ).click( function() {
  jQuery( '.burgerBun' ).removeClass( "open" );
  jQuery( '.menu-mobile-menu-container' ).removeClass( "open" );
} );

jQuery( window ).on( "scroll resize load", function() {
  if ( jQuery( document ).scrollTop() > 5 ) {
    jQuery( 'header' ).addClass( 'scroll' );
    jQuery( '.navbar-logo' ).attr( 'src', '/wp-content/themes/nuimage/assets/images/cahill-logo-icon.svg' );
  } else {
    jQuery( 'header' ).removeClass( 'scroll' );
    if ( window.location.href.indexOf( "acoustic-design" ) > -1 || window.location.href.indexOf( "fire-engineering" ) > -1 ) {
      jQuery( '.navbar-logo' ).attr( 'src', '/wp-content/themes/nuimage/assets/images/cahill-logo-full.svg' );
    } else {
      jQuery( '.navbar-logo' ).attr( 'src', '/wp-content/themes/nuimage/assets/images/cahill-logo-full.svg' );
    }
  }

  if ( window.location.href.indexOf( "privacy-policy" ) > -1 || window.location.href.indexOf( "terms-and-conditions" ) > -1 ) {
    jQuery( 'header' ).addClass( 'scroll' );
  }
} );