jQuery( 'img.svg' ).each( function() {
  const $img = jQuery( this );
  const imgID = $img.attr( 'id' );
  const imgClass = $img.attr( 'class' );
  const imgURL = $img.attr( 'src' );
  jQuery.get( imgURL, function( data ) {
    let $svg = jQuery( data ).find( 'svg' );
    if ( typeof imgID !== 'undefined' ) {
      $svg = $svg.attr( 'id', imgID );
    }
    if ( typeof imgClass !== 'undefined' ) {
      $svg = $svg.attr( 'class', imgClass + ' replaced-svg' );
    }
    $svg = $svg.removeAttr( 'xmlns:a' );
    if ( !$svg.attr( 'viewBox' ) && $svg.attr( 'height' ) && $svg.attr( 'width' ) ) {
      $svg.attr( 'viewBox', '0 0 ' + $svg.attr( 'height' ) + ' ' + $svg.attr( 'width' ) );
    }
    $img.replaceWith( $svg );
  }, 'xml' );
} );

jQuery( document ).on( 'ready', function() {
  jQuery( '.custom-pagination .prev' ).html( '<img src="/wp-content/themes/nuimage/assets/images/arrow-left-orange.svg" alt="Arrow Left">' );
  jQuery( '.custom-pagination .next' ).html( '<img src="/wp-content/themes/nuimage/assets/images/arrow-right-orange.svg" alt="Arrow Right">' );
} );


// jQuery(document).ready(function() {
//   jQuery('.custom-option').on('click', function() {
//     jQuery('#projectsFilterSubmit').click();
//   });
// })

jQuery( '.project-gallery-slider' ).slick( {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  centerMode: true,
  centerPadding: '50px',
  variableWidth: true,
  // prevArrow: jQuery(".our-services-include-prev"),
  prevArrow: '',
  nextArrow: jQuery( ".project-gallery-slider-next" ),
  responsive: [
    {
      breakpoint: 800,
      settings: {
        centerMode: false,
      }
    }
  ]
} );

jQuery( '.more-insight-slider' ).slick( {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  centerMode: false,
  centerPadding: '100px',
  prevArrow: jQuery( ".more-insight-slider-prev" ),
  nextArrow: jQuery( ".more-insight-slider-next" ),
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        centerPadding: '0px'
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        centerPadding: '0px',
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        centerPadding: '50px'
      }
    }
  ]
} );

// jQuery(document).ready(function() {
//   jQuery('.custom-option').on('click', function() {
//     jQuery('#insightsFilterSubmit').click();
//   });
// });

jQuery( '#footerOpenForm' ).on( 'click', function() {
  jQuery( ".footer-bottom" ).slideDown( "slow", function() {
    // Animation complete.
  } );
} );
jQuery( '#footerBottomClose' ).on( 'click', function() {
  jQuery( ".footer-bottom" ).hide( "slow" );
} );

jQuery( document ).on( 'ready', function() {
  if ( jQuery( window ).width() < 1024 ) {
    jQuery( '#singleImages' ).insertBefore( '#singleContent' );
  }
} );


// Add class 'in-view' when a specified elements come into view
const observer = new IntersectionObserver( entries => {
  // Loop over the entries
  entries.forEach( entry => {
    // If the element is visible
    if ( entry.isIntersecting ) {
      // Add the animation class
      entry.target.classList.add( 'in-view' );
    }
  } );
} );

if ( document.querySelector( '.staggered-section .line-1' ) ) {
  observer.observe( document.querySelector( '.staggered-section .line-1' ) );
}
if ( document.querySelector( '.staggered-section .line-2' ) ) {
  observer.observe( document.querySelector( '.staggered-section .line-2' ) );
}
if ( document.querySelector( '.introduction .line' ) ) {
  observer.observe( document.querySelector( '.introduction .line' ) );
}
if ( document.querySelector( '.project-type-card .line-1' ) ) {
  observer.observe( document.querySelector( '.project-type-card .line-1' ) );
}
if ( document.querySelector( '.project-type-card .line-2' ) ) {
  observer.observe( document.querySelector( '.project-type-card .line-2' ) );
}
if ( document.querySelector( '.project-type-card .line-3' ) ) {
  observer.observe( document.querySelector( '.project-type-card .line-3' ) );
}
if ( document.querySelector( '.mid-page-strip-banner .line' ) ) {
  observer.observe( document.querySelector( '.mid-page-strip-banner .line' ) );
}
if ( document.querySelector( '.banner-with-overlapping-image-right-container .line' ) ) {
  observer.observe( document.querySelector( '.banner-with-overlapping-image-right-container .line' ) );
}
if ( document.querySelector( '.timeline-card .line-1' ) ) {
  observer.observe( document.querySelector( '.timeline-card .line-1' ) );
}
if ( document.querySelector( '.timeline-card .line-2' ) ) {
  observer.observe( document.querySelector( '.timeline-card .line-2' ) );
}
if ( document.querySelector( '.timeline-card .line-3' ) ) {
  observer.observe( document.querySelector( '.timeline-card .line-3' ) );
}
if ( document.querySelector( '.footer-top .line' ) ) {
  observer.observe( document.querySelector( '.footer-top .line' ) );
}
if ( document.querySelector( '.get-in-touch-section .line' ) ) {
  observer.observe( document.querySelector( '.get-in-touch-section .line' ) );
}

// jQuery('#leaveUsAMessage').click(function() {
//   jQuery('#footerBottom').show(200);
// });

// jQuery('.footer-bottom .close').on('click', function() {
//   jQuery('#footerBottom').hide(200);
// });

jQuery( document ).on( 'ready', function() {
  jQuery( '.nav-contact-link a' ).attr( 'id', 'navContactLink' );
} );
