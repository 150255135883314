jQuery('.our-services-include-slider').slick({
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    centerPadding: '150px',  
    prevArrow: jQuery(".our-services-include-prev"),
    nextArrow: jQuery(".our-services-include-next"),  
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          centerPadding: '75px',
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          centerPadding: '30px',
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          centerPadding: '10px',
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          centerPadding: '10px',
        }
      },
    ]
  });

jQuery(document).ready(function() {
  if (jQuery(window).width() > 1200) {
    var slides = jQuery('.our-services-include-slider .slick-slide');
    slides.each(function(index) {
      if (jQuery(this).attr('aria-hidden') == 'false') {
        jQuery(this).prev().css('opacity', '1 !important');
      } else {
        jQuery(this).prev().css('opacity', '0.5 !important');
      }
    });
  }
});

jQuery('.our-services-include-slider ').on('afterChange', function(event, slick, currentSlide, nextSlide){
  if (jQuery(window).width() > 1200) {
    var slides = jQuery('.our-services-include-slider .slick-slide');
    slides.each(function(index) {
      if (jQuery(this).attr('aria-hidden') == 'false') {
        jQuery(this).prev().css('opacity', '1 !important');
      } else {
        jQuery(this).prev().css('opacity', '0.5 !important');
      }
    });
  }
});